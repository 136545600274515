import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ArgonBox from 'components/ArgonBox';

import { collapseArrow, collapseIcon, collapseIconBox, collapseItem, collapseText } from './styles/sidenavCollapse';

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from 'context';

function SidenavCollapse({ icon, name, children, active, noCollapse, open, ...rest }) {
  const [controller] = useArgonController();
  const { miniSidenav, darkSidenav, sidenavColor } = controller;

  return (
    <>
      <ListItem component="li">
        <ArgonBox {...rest} sx={(theme) => collapseItem(theme, { active, darkSidenav, sidenavColor, miniSidenav })}>
          <ListItemIcon sx={(theme) => collapseIconBox(theme, { active, darkSidenav, sidenavColor })}>
            {typeof icon === 'string' ? <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon> : icon}
          </ListItemIcon>

          <ListItemText primary={name} sx={(theme) => collapseText(theme, { miniSidenav, darkSidenav, active })} />

          <Icon sx={(theme) => collapseArrow(theme, { noCollapse, darkSidenav, miniSidenav, open })}>expand_less</Icon>
        </ArgonBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  color: 'info',
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;

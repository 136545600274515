import { BrowserCacheLocation, EventType, PublicClientApplication } from '@azure/msal-browser';
import App from 'App';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Soft UI Context Provider
import { ArgonControllerProvider } from 'context';

// react-perfect-scrollbar component
import PerfectScrollbar from 'react-perfect-scrollbar';

// react-perfect-scrollbar styles
import 'react-perfect-scrollbar/dist/css/styles.css';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'mkc-management-frontend-dev',
  env: 'development',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  allowedTracingUrls: ['https://api.development.mkc.bohnen.cloud'],
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    // cacheLocation: "sessionStorage",
    cacheLocation: BrowserCacheLocation.LocalStorage,

    storeAuthStateInCookie: false,
  },
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    pca.setActiveAccount(event.payload.account);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error('Login failed:', event.error);
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

try {
  root.render(
    <BrowserRouter>
      <ArgonControllerProvider>
        <PerfectScrollbar>
          <App msalInstance={pca} />
        </PerfectScrollbar>
      </ArgonControllerProvider>
    </BrowserRouter>
  );
} catch (error) {
  console.error('Rendering failed:', error);
}

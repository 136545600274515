import Swal from 'sweetalert2';

function useShowMessage() {
  const showAlert = ({
    title,
    text,
    icon,
    showCancelButton = false,
    confirmButtonText,
    cancelButtonText,
    footer,
    onConfirm,
    onCancel,
  }) => {
    Swal.fire({
      title: title || 'Etwas ist schiefgelaufen!',
      text: text || 'Bitte versuchen Sie es später noch einmal',
      icon: icon || 'error',
      showCancelButton,
      confirmButtonText: confirmButtonText || 'Try again',
      cancelButtonText: cancelButtonText || 'Support',
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
      footer: footer
        ? `<a href="/support">${footer}</a>`
        : '<a href="/support">oder kontaktieren Sie unseren Support.</a>',
    }).then((result) => {
      if (result.isConfirmed) {
        if (onConfirm) onConfirm();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if (onCancel) onCancel();
        // else window.location.href = '/support';
      }
    });
  };

  return { showAlert };
}

export default useShowMessage;

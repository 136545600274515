import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ErrorDevelopMessage from 'utility/ErrorDevelopMessage';
import useShowMessage from 'utility/useShowMessage';
function ActionCell({ user }) {
  console.log('USer', user);
  const { showAlert } = useShowMessage();
  const { accounts, instance } = useMsal();
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const handleUpdateClick = () => {
    setIsUpdateOpen(true);
  };

  const handleClose = () => {
    setIsUpdateOpen(false);
  };

  const handleDeleteClick = () => {
    const usersDeleteEndpoint = process.env.REACT_APP_BASE_URL + 'v1/users/delete';
    showAlert({
      title: `Delete User  ${user.name} `,
      text: `Are you sure you want to delete ${user.roleType.toLowerCase()} from your Organization?`,
      // text: `${user.roleType} ${user.name}: Are you sure you want to delete this user from your Organization?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancel',
      oncancel: () => {
        console.log('User deletion cancelled');
      },
      confirmButtonText: 'Yes, delete',
      onConfirm: async () => {
        const clientID = process.env.REACT_APP_CLIENT_ID;
        const accessTokenRequest = {
          scopes: [`api://${clientID}/API.Access`, 'openid'],
          account: accounts[0],
        };

        const callApi = async (accessToken) => {
          try {
            console.log('Making DELETE request to:', usersDeleteEndpoint);

            console.log(user.email);
            const response = await axios.delete(usersDeleteEndpoint, {
              headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'text/plain', // in case that request is a string
                Authorization: `Bearer ${accessToken}`,
              },
              // data: user.email, // email as string
              data: { email: user.email },
            });
            console.log('Here is response after delete', response);
            return response.data;
          } catch (error) {
            console.error('Axios error:', error);
            throw error;
          }
        };
        try {
          const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
          await callApi(accessTokenResponse.accessToken);
          // Show success alert
          showAlert({
            title: 'Success',
            text: `User ${user.name} was deleted successfully.`,
            icon: 'success',
            confirmButtonText: 'Ok',
            footer: 'Need more Support? Contact us',
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          } else {
            console.error('Error:', error);
            return <ErrorDevelopMessage error={error.message} />;
          }
        }
      },

      footer: 'This action cannot be undone.Need Help?',
    });
    console.log('I am here');
  };
  return (
    <ArgonBox display="flex" alignItems="center">
      {/* <ArgonTypography variant="body1" color="secondary" sx={{ cursor: 'pointer', lineHeight: 0 }}>
        <Tooltip title="Preview product" placement="top">
          <Icon>visibility</Icon>
        </Tooltip>
      </ArgonTypography> */}

      {/* add here actions for edit and delete projects */}
      {/* <ArgonBox mx={2}>
        <ArgonTypography variant="body1" color="secondary" sx={{ cursor: 'pointer', lineHeight: 0 }}>
          <Tooltip title="Edit User" placement="top">
            <Icon onClick={handleUpdateClick}>edit</Icon>
          </Tooltip>
        </ArgonTypography>
      </ArgonBox> */}
      <ArgonTypography variant="body1" color="secondary" sx={{ cursor: 'pointer', lineHeight: 0 }}>
        <Tooltip title="Delete Project" placement="left">
          <Icon onClick={handleDeleteClick}>delete</Icon>
        </Tooltip>
        {/* <Button onClick={handleDeleteClick}>Delete</Button> */}
      </ArgonTypography>
      {/* {isUpdateOpen && <UpdateUser user={user} onClose={handleClose} showAlert={showAlert} />} */}
    </ArgonBox>
  );
}

export default ActionCell;

ActionCell.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    roleType: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

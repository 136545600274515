import { Button, Card, CardActions, CardMedia, Grid, Icon, Typography } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import { useArgonController } from 'context';

import ArgonTypography from 'components/ArgonTypography';
import { useNavigate } from 'react-router-dom';
import docu from '../../assets/images/documentation.png';
import onBoardingImg from '../../assets/images/on_boarding.png';
import support from '../../assets/images/support.png';

const HomeCards = () => {
  const [controller, dispatch] = useArgonController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const handleOnboarding = () => {
    navigate('/management/onboarding');
  };
  const handleDocumentation = () => {
    navigate('/documentation/configuration');
  };
  const handleSupport = () => {
    navigate('/support');
  };

  const useResponsiveCardMediaStyle = ({ theme, height }) => ({
    height: theme.functions.pxToRem(height), // Convert the height to rem for consistency
    objectFit: 'cover',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: theme.functions.pxToRem(height - 60), // Example adjustment for smaller screens
    },
    // Add other responsive adjustments or common styles here
  });

  const cardMediaData = [
    {
      src: onBoardingImg,
      alt: 'On Boarding',
      title: 'Onboarding',
      titleSecondary: 'Proceed with Onboarding',
      action: handleOnboarding,
      typographyVariant: 'h4',
      icon: 'airplane_ticket',
      buttonLabel: 'Start Now',
    },
    {
      src: docu,
      alt: 'Documentation',
      title: 'Documentation',
      titleSecondary: 'Check more Documentation',
      action: handleDocumentation,
      typographyVariant: 'h4',
      icon: 'assignment',
      buttonLabel: 'Learn More',
    },
    {
      src: support,
      alt: 'Support',
      title: 'Support',
      titleSecondary: 'Reach us to get more Support',
      action: handleSupport,
      typographyVariant: 'h4',
      icon: 'support_agent',
      buttonLabel: 'Need Help',
    },
  ];

  return (
    <ArgonBox py={1} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container justifyContent="center" alignItems="center" spacing={2} mb={3}>
        {cardMediaData.map((data, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Adjust as needed
                padding: 2,
              }}
            >
              <Typography variant="h6" component="div">
                {data.titleSecondary}
              </Typography>
              <ArgonTypography variant={data.typographyVariant} fontWeight="bold" color={darkMode ? 'white' : 'dark'}>
                {data.title}
                <Icon color="primary" sx={{ marginLeft: 2 }}>
                  {data.icon}
                </Icon>
              </ArgonTypography>
              <CardMedia
                component="img"
                src={data.src}
                alt={data.alt}
                sx={(theme) => useResponsiveCardMediaStyle({ theme, height: 240 })}
              />
              <CardActions>
                <Button variant="contained" onClick={data.action}>
                  {data.buttonLabel}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </ArgonBox>
  );
};

export default HomeCards;

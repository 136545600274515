import { useMsal } from '@azure/msal-react';
import { Card } from '@mui/material';
import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';
import { useEffect, useState } from 'react';

const WelcomeMessage = () => {
  const { instance } = useMsal();
  const [username, setUsername] = useState('');
  const [greeting, setGreeting] = useState('');
  const [controller, dispatch] = useArgonController();

  const { darkMode } = controller;
  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      // console.log('Current Acount', currentAccount.name);
      setUsername(currentAccount.name);
    }
    setGreeting(getTimeBasedGreeting());
  }, [instance]);

  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 5) {
      return 'Guten Nacht';
    } else if (hour < 12) {
      return 'Guten Morgen';
    } else if (hour < 17) {
      return 'Guten Tag';
    } else {
      return 'Guten Abend';
    }
  };
  return (
    <Card
      sx={{
        display: 'grid',
        alignItems: 'center',
        position: 'relative',
        height: '6rem',
        borderRadius: 'lg',
        mb: 3,
      }}
    >
      <ArgonTypography variant="h4" color={darkMode ? 'white' : 'dark'} textAlign="center">
        {greeting} {username}
      </ArgonTypography>
      <ArgonTypography variant="h6" color={darkMode ? 'white' : 'dark'} textAlign="center">
        <p>Willkommen beim MKC Portal</p>
      </ArgonTypography>
    </Card>
  );
};

export default WelcomeMessage;

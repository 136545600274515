import { useMsal } from '@azure/msal-react';
import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import { setIsAdmin, useArgonController } from 'context';
import React from 'react';
import useFetchData from './useFetchData';
import useShowMessage from './useShowMessage';
const checkGlobalRole = () => {
  const { instance, inProgress, accounts } = useMsal();
  const apiEndpoint = process.env.REACT_APP_BASE_URL + 'v1/api/userinfo';

  const { data, loading, error } = useFetchData(instance, accounts, inProgress, apiEndpoint);
  const { showAlert } = useShowMessage();
  const [state, dispatch] = useArgonController();

  React.useEffect(() => {
    if (data) {
      switch (data.globalRole) {
        case 'OWNER':
          setIsAdmin(dispatch, true);
          break;
        // Margo Add more cases for other roles
        default:
          setIsAdmin(dispatch, false);
          break;
      }
    }
  }, [data, dispatch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    showAlert({
      title: 'Error Loading Data',
      text: 'No role found. Check with Admin or try again later.',
      icon: 'error',
    });
    return null;
  }
};

export default checkGlobalRole;

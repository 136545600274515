import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import LoadingSpinner from '../../loader/LoadingSpinner';

const LogOut = () => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogout = (event) => {
    event.preventDefault();
    setLoading(true);

    if (accounts.length === 0) {
      setError('No accounts found. User might not be logged in.');
      setLoading(false);
      return;
    }

    const homeAccountId = accounts[0].homeAccountId;
    if (!homeAccountId) {
      setError('HomeAccountId not found in session storage');
      setLoading(false);
      return;
    }

    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: '/login',
    };

    instance
      .logoutRedirect(logoutRequest)
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
      })
      .catch((error) => {
        setError(error.message || 'Logout failed');
        setLoading(false);
      });
  };

  return (
    <div onClick={handleLogout}>
      {error && <div>Error: {error}</div>}
      {loading ? <LoadingSpinner /> : <span>Log out</span>}
    </div>
  );
};

export default LogOut;

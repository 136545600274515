import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

import { keyframes } from '@emotion/react';
import DashboardLayout from 'components-mck/dashboard/DashboardLayout';
import ArgonButton from 'components/ArgonButton';
import ArgonInput from 'components/ArgonInput';
import { Link, useNavigate } from 'react-router-dom';

function AddProject() {
  //   const [skills, setSkills] = useState(['react', 'angular']);
  const navigate = useNavigate();

  const slideLeft = keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100px);
    }
  `;
  return (
    <DashboardLayout>
      <ArgonBox
        component="form"
        role="form"
        bgColor="secondary"
        variant="gradient"
        borderRadius="lg"
        p={10}
        style={{
          //   animation: 'slide-left 10s cubic-bezier(.215,.61,.355,1.000) 2s both',
          //   animationName: `${slideLeft}`,
          animation: `10s cubic-bezier(.215,.61,.355,1.000) 12s both ${slideLeft}`,
          height: '50rem',
        }}
      >
        <ArgonBox mb={2}>
          <ArgonInput placeholder="Name of the Project" size="large" />
        </ArgonBox>

        <ArgonBox mb={2}>
          <ArgonInput type="email" placeholder="Email@example.de" size="large" />
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput type="password" placeholder="Password" size="large" />
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center">
          <ArgonTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            After you create the project, you can add more details
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="center" flexDirection={{ xs: 'column', sm: 'row' }} mt={5}>
          <ArgonButton variant="gradient" color="info">
            Create new Project
          </ArgonButton>
          <ArgonBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <ArgonButton variant="gradient" color="error" sx={{ height: '100%' }} onClick={() => navigate(-1)}>
              Cancel
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox mt={3} textAlign="center">
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Do you need Help?&nbsp;
            <ArgonTypography component={Link} to="/support" variant="button" color="info" fontWeight="bold">
              Help
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>

      {/* <Card id="basic-info" sx={{ overflow: 'visible' }}> */}
      {/* <ArgonBox component="form" pb={3} px={30}>
        <ArgonBox p={9}>
          <ArgonTypography variant="h5">Add New Project</ArgonTypography>
        </ArgonBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="first name" placeholder="Alec" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="last name" placeholder="Thompson" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <ArgonBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                  <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                      I&apos;m
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonSelect placeholder="Male" options={exampleData.gender} />
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <ArgonBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          birth date
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonSelect placeholder="February" options={exampleData.birthDate} />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ArgonBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                      <ArgonSelect placeholder={1} options={exampleData.days} />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                      <ArgonSelect placeholder={2021} options={exampleData.years} />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="email" placeholder="example@email.com" inputProps={{ type: 'email' }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="confirmation email" placeholder="example@email.com" inputProps={{ type: 'email' }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="your location" placeholder="Sydney, A" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="phone number" placeholder="+40 735 631 620" inputProps={{ type: 'number' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField label="language" placeholder="English" />
          </Grid>
          <Grid item xs={12} md={6}>
            <ArgonBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <ArgonTagInput
                tags={skills}
                placeholder=" "
                onChange={(newSkill) => setSkills(newSkill)}
                removeOnBackspace
              />
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox> */}
      {/* </Card> */}
    </DashboardLayout>
  );
}

export default AddProject;

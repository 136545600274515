import { Card, Grid } from '@mui/material';
import DashboardLayout from 'components-mck/dashboard/DashboardLayout';
import DashboardNavbar from 'components-mck/dashboard/DashboardNavbar/DashboardNavbar';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import { useEffect, useState } from 'react';

const Documentation = () => {
  const [docContent, setDocContent] = useState('');
  //   const usernameGitHub = process.env.REACT_DOC_GIT_HUB;
  //   console.log(usernameGitHub);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://raw.githubusercontent.com/marinaBezmosciuc/MKC-test2/main/README.md`;
        const response = await fetch(url);
        const data = await response.text(); // Use response.text() instead of response.json()
        if (response.ok) {
          setDocContent(data); // Set the content directly, no need to decode from base64
        } else {
          console.error('Failed to fetch documentation:', data);
        }
      } catch (error) {
        console.error('Error fetching documentation:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox pt={3} pb={8} position="relative">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <ArgonBox p={3}>
                <ArgonTypography>I am dok</ArgonTypography>
                <ArgonTypography>
                  <div>
                    <pre>{docContent} </pre>
                  </div>
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
};

export default Documentation;

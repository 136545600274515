import { createContext, useContext, useMemo, useReducer } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// The Argon Dashboard 2 PRO MUI main context
const Argon = createContext(null);

// Setting custom name for the context which is visible on react dev tools
Argon.displayName = 'MKCContext';

// Argon Dashboard 2 PRO MUI reducer
function reducer(state, action) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value };
    }
    case 'DARK_SIDENAV': {
      return { ...state, darkSidenav: action.value };
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value };
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value };
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value };
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value };
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value };
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value };
    }
    case 'DARK_MODE': {
      return { ...state, darkMode: action.value };
    }
    case 'SET_IS_ADMIN': {
      return { ...state, isAdmin: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Argon Dashboard 2 PRO MUI context provider
function ArgonControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    darkSidenav: false,
    sidenavColor: null,
    transparentNavbar: true,
    fixedNavbar: false,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false,
    isAdmin: false,
  };

  // const [controller, dispatch] = useReducer(reducer, initialState);

  //Maintain the state of the dark mode theme
  const [controller, dispatch] = useReducer(reducer, {}, () => {
    const localData = localStorage.getItem('DarkModeTheme');
    return {
      miniSidenav: false,
      darkSidenav: localData === 'true',
      sidenavColor: null,
      transparentNavbar: true,
      fixedNavbar: false,
      // openConfigurator: false,
      // direction: 'ltr',
      layout: 'dashboard',
      darkMode: localData === 'true',
    };
  });

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <Argon.Provider value={value}>{children}</Argon.Provider>;
}

// Argon Dashboard 2 PRO MUI custom hook for using context
function useArgonController() {
  const context = useContext(Argon);

  if (!context) {
    throw new Error('useArgonController should be used inside the ArgonControllerProvider.');
  }

  return context;
}

// Typechecking props for the ArgonControllerProvider
ArgonControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: 'MINI_SIDENAV', value });
const setDarkSidenav = (dispatch, value) => dispatch({ type: 'DARK_SIDENAV', value });
const setSidenavColor = (dispatch, value) => dispatch({ type: 'SIDENAV_COLOR', value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: 'TRANSPARENT_NAVBAR', value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: 'FIXED_NAVBAR', value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: 'OPEN_CONFIGURATOR', value });
const setDirection = (dispatch, value) => dispatch({ type: 'DIRECTION', value });
const setLayout = (dispatch, value) => dispatch({ type: 'LAYOUT', value });
const setDarkMode = (dispatch, value) => dispatch({ type: 'DARK_MODE', value });
const setIsAdmin = (dispatch, value) => dispatch({ type: 'SET_IS_ADMIN', value });

export {
  ArgonControllerProvider,
  setDarkMode,
  setDarkSidenav,
  setDirection,
  setFixedNavbar,
  setIsAdmin,
  setLayout,
  setMiniSidenav,
  setOpenConfigurator,
  setSidenavColor,
  setTransparentNavbar,
  useArgonController,
};

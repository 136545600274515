import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DashboardLayout from 'components-mck/dashboard/DashboardLayout';
import DashboardNavbar from 'components-mck/dashboard/DashboardNavbar/DashboardNavbar';
import Footer from 'components-mck/footer/Footer';
import ArgonBox from 'components/ArgonBox';
import MyUsersData from './MyUsersData';

const MyUsers = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: '100%' }}>
          <Grid item xs={12} lg={11}>
            <Card sx={{ height: '100%' }}>
              <MyUsersData />
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
};

export default MyUsers;

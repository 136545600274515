import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import brandDark from 'assets/images/logo-mkc-dark.png';
import brand from 'assets/images/logo-mkc.png';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';
import themeRTL from 'assets/theme/theme-rtl';
import { setMiniSidenav, useArgonController } from 'context';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from 'routesMKC';
import Sidenav from './components-mck/Sidenav/Sidenav';

import 'assets/css/nucleo-icons.css';
import 'assets/css/nucleo-svg.css';
import axios from 'axios';
import ErrorBoundary from 'components-mck/error/ErrorBoundary';
import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import AppRoutes from './AppRoutes';

export default function App({ msalInstance }) {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, layout, sidenavColor, darkSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { inProgress } = useMsal();

  if (inProgress === 'startup') {
    return <LoadingSpinner />;
  }
  // const documentationComponents = importDocumentationComponents();

  // // Function to get documentation routes
  // const getDocumentationRoutes = () =>
  //   Object.entries(documentationComponents).map(([displayName, { component: Component, ...rest }]) => (
  //     <Route
  //       key={rest.key}
  //       path={rest.route}
  //       element={
  //         <React.Suspense fallback={<div>Loading...</div>}>
  //           <Component {...rest} />
  //         </React.Suspense>
  //       }
  //     />
  //   ));

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}v1/api/health`)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log('Response from health', response);
        }
      })
      .catch((error) => {
        console.log('Error: ' + error);
      });
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <ErrorBoundary>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          <AuthenticatedTemplate>
            {layout === 'dashboard' && (
              <React.Fragment>
                {/* <DashboardLayout>
                  <DashboardNavbar />
                </DashboardLayout> */}
                <Sidenav
                  color={sidenavColor}
                  brand={darkSidenav || darkMode ? brand : brandDark}
                  brandName="MKC - Dashboard"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </React.Fragment>
            )}
          </AuthenticatedTemplate>
          <AppRoutes />
        </ThemeProvider>
      </ErrorBoundary>
    </MsalProvider>
  );
}

App.propTypes = {
  msalInstance: PropTypes.instanceOf(PublicClientApplication).isRequired,
};

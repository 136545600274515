/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base colors for the Argon Dashboard 2 PRO MUI.
 * You can add new color using this file.
 * You can customized the colors for the entire Argon Dashboard 2 PRO MUI using thie file.
 */

const colors = {
  background: {
    default: '#f8f9fa',
    dark: '#172b4d',
  },

  text: {
    main: '#67748e',
    focus: '#67748e',
  },

  transparent: {
    main: 'transparent',
  },

  white: {
    main: '#ffffff',
    focus: '#ffffff',
  },

  black: {
    light: '#141414',
    main: '#000000',
    focus: '#000000',
  },

  primary: {
    main: '#5e72e4',
    focus: '#5e72e4',
  },

  secondary: {
    main: '#8392ab',
    focus: '#8392ab',
  },

  info: {
    // main: "#11cdef",
    // focus: "#11cef",
    focus: '#fbb900',
    main: '#fbb900',
  },

  success: {
    main: '#2dce89',
    focus: '#2dce89',
  },

  warning: {
    main: '#fb6340',
    focus: '#fb6340',
  },

  error: {
    main: '#f5365c',
    focus: '#f5365c',
  },

  light: {
    main: '#e9ecef',
    focus: '#e9ecef',
  },

  dark: {
    main: '#344767',
    focus: '#344767',
  },

  grey: {
    100: '#f8f9fa',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529',
  },

  gradients: {
    primary: {
      main: '#5e72e4',
      state: '#825ee4',
    },

    secondary: {
      main: '#627594',
      state: '#a8b8d8',
    },

    info: {
      // main: "#11cdef",
      // state: "#11cef",
      main: '#fbb900',
      state: '#fbb900',
    },

    success: {
      main: '#2dce89',
      state: '#2dcecc',
    },

    warning: {
      main: '#fb6340',
      state: '#fbb140',
    },

    error: {
      main: '#f5365c',
      state: '#f56036',
    },

    light: {
      main: '#ced4da',
      state: '#ebeff4',
    },

    dark: {
      main: '#212229',
      state: '#212529',
    },
  },

  socialMediaColors: {
    facebook: {
      main: '#3b5998',
      dark: '#344e86',
    },

    twitter: {
      main: '#55acee',
      dark: '#3ea1ec',
    },

    instagram: {
      main: '#125688',
      dark: '#0e456d',
    },

    linkedin: {
      main: '#0077b5',
      dark: '#00669c',
    },

    pinterest: {
      main: '#cc2127',
      dark: '#b21d22',
    },

    youtube: {
      main: '#e52d27',
      dark: '#d41f1a',
    },

    vimeo: {
      main: '#1ab7ea',
      dark: '#13a3d2',
    },

    slack: {
      main: '#3aaf85',
      dark: '#329874',
    },

    dribbble: {
      main: '#ea4c89',
      dark: '#e73177',
    },

    github: {
      main: '#24292e',
      dark: '#171a1d',
    },

    reddit: {
      main: '#ff4500',
      dark: '#e03d00',
    },

    tumblr: {
      main: '#35465c',
      dark: '#2a3749',
    },
  },

  alertColors: {
    primary: {
      main: '#5e72e4',
      state: '#673bde',
      border: '#cfd5f7',
    },

    secondary: {
      main: '#627594',
      state: '#8ca1cb',
      border: '#dadee6',
    },

    info: {
      // main: '#1171ef',
      state: '#0eadca',
      border: '#b8f0fa',
      focus: '#fbb900',
    },

    success: {
      main: '#2dce89',
      state: '#26adab',
      border: '#c0f0dc',
    },

    warning: {
      main: '#fb6340',
      state: '#faa118',
      border: '#fed0c6',
    },

    error: {
      main: '#f5365c',
      state: '#f3410f',
      border: '#fcc3ce',
    },

    light: {
      main: '#ced4da',
      state: '#d1dae6',
      border: '#f8f9fa',
    },

    dark: {
      main: '#212229',
      state: '#0f1112',
      border: '#c2c8d1',
    },
  },

  badgeColors: {
    primary: {
      background: '#eaecfb',
      text: '#2643e9',
    },

    secondary: {
      background: '#e4e8ed',
      text: '#5974a2',
    },

    info: {
      // background: '#aaedf9',
      // text: '#03acca',
      background: '#fbb900',
      text: '#fbb900',
    },

    success: {
      background: '#b0eed3',
      text: '#1aae6f',
    },

    warning: {
      background: '#fee6e0',
      text: '#ff3709',
    },

    error: {
      background: '#fdd1da',
      text: '#f80031',
    },

    light: {
      background: '#ffffff',
      text: '#c7d3de',
    },

    dark: {
      background: '#8097bf',
      text: '#1e2e4a',
    },
  },

  inputColors: {
    borderColor: { main: '#d2d6da', focus: '#11cdef' },
    error: '#f5365c',
    success: '#2dce89',
  },

  sliderColors: {
    thumb: { borderColor: '#d9d9d9' },
  },

  circleSliderColors: {
    background: '#d3d3d3',
  },

  tabs: {
    indicator: { boxShadow: '#ddd' },
  },
};

export default colors;

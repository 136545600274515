// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components-mck/sign-in/illustrationLayout.scss"],"names":[],"mappings":"AAaA;EACE;IACE,sBAAA;IAEA,kBAAA;IACA,UAAA;EACF;EACA;IAEE,iBAAA;IACA,UAAA;EACF;AACF","sourcesContent":["@-webkit-keyframes focus-in-expand {\n  0% {\n    letter-spacing: -0.5em;\n    -webkit-filter: blur(12px);\n    filter: blur(12px);\n    opacity: 0;\n  }\n  100% {\n    -webkit-filter: blur(0px);\n    filter: blur(0px);\n    opacity: 1;\n  }\n}\n@keyframes focus-in-expand {\n  0% {\n    letter-spacing: -0.5em;\n    -webkit-filter: blur(12px);\n    filter: blur(12px);\n    opacity: 0;\n  }\n  100% {\n    -webkit-filter: blur(0px);\n    filter: blur(0px);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

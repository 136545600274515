import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Card } from '@mui/material';

import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import microsoftLogo from '../../assets/images/logos/microsoft-logo.png';
import LoadingSpinner from '../loader/LoadingSpinner'; // Make sure you have this component
import IllustrationLayout from './IllustrationLayout';
import ParticleBg from './ParticleBg';

function Login() {
  const { instance, accounts } = useMsal();
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setIsLoading(true);
    setError(null);
    const clientID = process.env.REACT_APP_CLIENT_ID;

    const loginRequest = {
      scopes: [`api://${clientID}/API.Access`, 'openid'],
      account: accounts[0],
      prompt: 'login',
    };
    // console.log('Here is my instace', instance);
    try {
      // Artificial delay for demonstration
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (accounts.length > 0) {
        try {
          await instance.acquireTokenSilent(loginRequest);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.loginRedirect(loginRequest);
          } else {
            throw error;
          }
        }
      } else {
        await instance.loginRedirect(loginRequest);
      }

      navigate('/home');
    } catch (error) {
      console.error(error);
      setError('An error occurred during sign in. Please try again.');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (accounts.length > 0) {
      instance
        .handleRedirectPromise()
        .then((response) => {
          if (response !== null) {
            navigate('/home');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accounts, navigate, instance]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <IllustrationLayout
      title="Welcome!"
      description="Please proceed with sign in with your Microsoft Account"
      illustration={{
        title: 'MKC',
        description: 'Simplifying Kubernetes Management',
      }}
    >
      <ParticleBg />
      <Card
        sx={{
          height: '40vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0.8,
        }}
      >
        {error && <p>Error signing in: {error}</p>}
        <ArgonBox p={3} textAlign="center">
          <ArgonTypography variant="h2" fontWeight="medium" sx={{ my: 2 }}>
            Welcome
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox px={6} pb={3} textAlign="center">
          <ArgonTypography display="block" variant="button" color="secondary" fontWeight="regular" sx={{ mb: 3 }}>
            Please use your Microsoft Account
          </ArgonTypography>
          <Box display="flex" justifyContent="center" flexDirection="row" margin="2.5em">
            <Button
              onClick={handleSignIn}
              disabled={isLoading}
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '17rem',
                fontFamily: 'Segoe UI',
                fontSize: '15px',
                fontWeight: 600,
                padding: '12px',
                borderRadius: '5px',
                border: 'none',
                outline: 'none',
                transition: '0.4s ease-in-out',
                backgroundColor: '#2f2f2f',
                color: '#ffffff',
                minWidth: '150px',
                minHeight: '50px',
                '&:hover': {
                  backgroundColor: '#fbb900',
                  color: '#031b34',
                },
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '10px',
                  }}
                >
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  <img className="microsoft-logo" src={microsoftLogo} alt="Microsoft Logo" />
                  Sign in with Microsoft
                </>
              )}
            </Button>
          </Box>
        </ArgonBox>
      </Card>
    </IllustrationLayout>
  );
}

export default Login;

import Grid from '@mui/material/Grid';

import ArgonBox from 'components/ArgonBox';

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from '../dashboard/DashboardLayout';
import Footer from '../footer/Footer';
// import CategoriesList from 'examples/Lists/CategoriesList';
import DashboardNavbar from '../dashboard/DashboardNavbar/DashboardNavbar';
// import SalesTable from 'examples/Tables/SalesTable';
// import Table from 'examples/Tables/Table';

import typography from 'assets/theme/base/typography';

import BalanceCard from 'layouts/dashboards/default/components/BalanceCard';
import CryptoCard from 'layouts/dashboards/default/components/CryptoCard';
import ProgressTrack from 'layouts/dashboards/default/components/ProgressTrack';
import TeamMembers from 'layouts/dashboards/default/components/TeamMembers';
import TodoList from 'layouts/dashboards/default/components/TodoList';

// Data

function DashboardDisplay() {
  const { size } = typography;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={4}>
            <TeamMembers />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TodoList />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ProgressTrack />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid container item xs={12} lg={7} spacing={3}>
            <Grid item xs={12} height="max-content">
              <ArgonBox
                sx={{
                  '& .MuiTableContainer-root': {
                    p: 3,
                  },
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              ></ArgonBox>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <BalanceCard />
              </Grid>
              <Grid item xs={12} md={6}>
                <CryptoCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ArgonBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            ></ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DashboardDisplay;

/** 
  All of the routes for the Argon Dashboard 2 PRO MUI are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 PRO MUI layouts
import Home from './components-mck/home/Home';

// Argon Dashboard 2 PRO MUI components
import { Icon } from '@mui/material';
import DashboardDisplay from 'components-mck/dashboardDisplay/DashboardDisplay';
import Documentation from 'components-mck/documentation/Documentation';
import MyProjects from 'components-mck/management/myProjectData/MyProjects';
import MyUsers from 'components-mck/management/myUsers/MyUsers';
import Support from 'components-mck/support/Support';
import { importDocumentationComponents } from './utility/utility';

const documentationComponents = importDocumentationComponents();

const documentationRoutes = documentationComponents.map(({ name, key, route, component }) => ({
  name,
  key,
  route,
  component: <Documentation />,
}));
// console.log('FILE PATH FROM THE ROUTES MKC', filePath);

const routes = [
  // {
  //   name: 'Illustration',
  //   key: 'illustration',
  //   route: '/login',
  //   component: <SignIn />,
  // },
  // { type: 'title', title: 'Dashboard', key: 'title-pages' },
  {
    type: 'collapse',
    dropdown: false,
    name: 'Home',
    key: 'home',
    icon: (
      <Icon color="info" fontSize="14px">
        home
      </Icon>
    ),
    route: '/home',
    component: <Home />,
    noCollapse: true,
  },
  { type: 'divider', key: 'divider-1' },

  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: (
      <Icon color="warning" fontSize="14px">
        dashboard_customize
      </Icon>
    ),
    collapse: [
      {
        name: 'Overview',
        key: 'overview',
        route: '/dashboard',
        component: <DashboardDisplay />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Organization',
    key: 'organization',
    adminOnly: true,
    icon: (
      <Icon color="success" fontSize="14px">
        corporate_fare
      </Icon>
    ),
    collapse: [
      {
        name: 'Users',
        key: 'users',
        route: 'admin/organization/users',
        component: <MyUsers />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Projects',
    key: 'projects',
    icon: (
      <Icon color="primary" fontSize="14px">
        snowboarding{' '}
      </Icon>
    ),
    collapse: [
      // Disable On Boarding for now Margo
      // {
      //   name: 'On Boarding',
      //   key: 'on-boarding',
      //   route: '/management/onboarding',
      //   component: <Onboarding />,
      // },
      {
        name: 'My Projects',
        key: 'my-projects',
        route: '/management/my-projects',
        component: <MyProjects />,
      },
      {
        name: 'Users',
        key: 'users',
        route: '/management/users',
        component: <MyUsers />,
      },
    ],
  },

  {
    type: 'collapse',
    name: 'Documentation',
    key: 'documentation',

    icon: (
      <Icon color="success" fontSize="14px">
        drive_file_move{' '}
      </Icon>
    ),
    collapse: documentationRoutes,
  },
  {
    type: 'collapse',
    name: 'Support',
    key: 'support',
    icon: (
      <Icon color="error" fontSize="14px">
        privacy_tip
      </Icon>
    ),
    route: '/support',
    component: <Support />,
    noCollapse: true,
  },
];

export default routes;

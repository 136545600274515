import Link from '@mui/material/Link';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';

import darkLogoMKC from 'assets/images/logo-mkc-dark.png';
import lightLogoMKC from 'assets/images/logo-mkc.png';

function SidenavFooter() {
  const [controller] = useArgonController();
  const { miniSidenav, darkMode, darkSidenav } = controller; // Assuming darkMode is part of your controller
  // const { miniSidenav, darkSidenav } = controller;
  const logoSrc = darkMode || darkSidenav ? lightLogoMKC : darkLogoMKC;

  return (
    <ArgonBox opacity={miniSidenav ? 0 : 1} sx={{ transition: 'opacity 200ms linear' }}>
      <ArgonBox position="relative" textAlign="center">
        <ArgonBox component="img" src={logoSrc} alt="sidebar_illustration" width="40%" />
        <ArgonBox width="100%" pb={2} px={2} color={darkSidenav ? 'white' : 'dark'} textAlign="center" lineHeight={0}>
          <ArgonTypography color="inherit" variant="h6">
            Need help?
          </ArgonTypography>
          <ArgonTypography color="light" variant="caption">
            Please check our docs
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonButton
          component={Link}
          // href="https://www.creative-tim.com/product/argon-dashboard-pro-material-ui"
          target="_blank"
          rel="noreferrer"
          color="info"
          size="small"
          fullWidth
          mb={2}
        >
          Chat
        </ArgonButton>
      </ArgonBox>
    </ArgonBox>
  );
}

export default SidenavFooter;

import { Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import PropTypes from 'prop-types';

function ProductCell({ name, count }) {
  return (
    <ArgonBox display="flex" alignItems="center" height="10%">
      {/* <Checkbox defaultChecked={checked} /> */}
      <ArgonTypography variant="button" fontWeight="medium">
        {count}
      </ArgonTypography>
      <ArgonBox mx={2}>
        <ArgonTypography variant="body2" color="secondary" sx={{ cursor: 'pointer', lineHeight: 0 }}>
          <Tooltip title="Edit product" placement="top">
            <Icon>token</Icon>
          </Tooltip>
        </ArgonTypography>
      </ArgonBox>

      <ArgonTypography variant="button" fontWeight="medium">
        {name}
      </ArgonTypography>
    </ArgonBox>
  );
}

ProductCell.defaultProps = {
  checked: false,
};

ProductCell.propTypes = {
  // image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default ProductCell;

import axios from 'axios';
import { useState } from 'react';
import useShowMessage from './useShowMessage';
import { handleError } from './utility';

const usePostData = (instance, accounts, inProgress, apiEndpoint) => {
  const { showAlert } = useShowMessage();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const clientID = process.env.REACT_APP_CLIENT_ID;
  const accessTokenRequest = {
    scopes: [`api://${clientID}/API.Access`, 'openid'],
    account: accounts[0],
  };

  const postData = async (data) => {
    setLoading(true);
    setError('');
    try {
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      const response = await axios.post(apiEndpoint, data, {
        headers: {
          Authorization: `Bearer ${accessTokenResponse.accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Post error:', error);
      const errorMessage = handleError(error);
      let serverMessage = 'An error occurred while posting the data. Please try again later.';
      if (error.response && error.response.data) {
        serverMessage = error.response.data.error || serverMessage;
        console.log('Server Message:', serverMessage.error);
      }
      showAlert({
        title: 'Oops! Something went wrong.',
        text: serverMessage,
        icon: 'error',
      });
      setError(errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { postData, loading, error };
};

export default usePostData;

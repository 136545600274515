import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import { useState } from 'react';
import '../../../styles/overlayCards.css';
import AddUser from './addUser/AddUser';
import FetchingUsersData from './components/FetchingUsersData';

function MyUsersData() {
  const [showAddUser, setShowAddUser] = useState(false);

  return (
    <div>
      {showAddUser && (
        <div className="overlay">
          <AddUser onClose={() => setShowAddUser(false)} />
        </div>
      )}
      <ArgonBox my={3}>
        <Card>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <ArgonBox lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium">
                All Users
              </ArgonTypography>
              <ArgonTypography variant="button" fontWeight="regular" color="text">
                Here we can add some information about Users if its needed ?
              </ArgonTypography>
            </ArgonBox>
            <Stack spacing={1} direction="row">
              <ArgonButton variant="gradient" color="info" size="small" onClick={() => setShowAddUser(true)}>
                + Add User
              </ArgonButton>
            </Stack>
          </ArgonBox>
          <FetchingUsersData />
        </Card>
      </ArgonBox>
    </div>
  );
}

export default MyUsersData;

import ArgonBox from 'components/ArgonBox';
import PropTypes from 'prop-types';

function DataTableBodyCell({ noBorder, align, children }) {
  return (
    <ArgonBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ borders: { borderWidth, borderColor }, typography: { size } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? 0 : `${borderWidth[1]} solid ${borderColor}`,
      })}
    >
      <ArgonBox display="inline-block" width="max-content" color="text" sx={{ verticalalign: 'middle' }}>
        {children}
      </ArgonBox>
    </ArgonBox>
  );
}

DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: 'left',
};

DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

export default DataTableBodyCell;

import { useMsal } from '@azure/msal-react';
import DataTable from '../../components/DataTable';
/* eslint-disable react/prop-types */
import ArgonBadge from 'components/ArgonBadge';

import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import ErrorDevelopMessage from 'utility/ErrorDevelopMessage';
import useFetchData from '../../../../utility/useFetchData';
import ActionCell from '../../components/actionCell';
import ProductCell from '../../components/productCell';

const FetchingProjectData = () => {
  const { instance, inProgress, accounts } = useMsal();
  const ProjectListEndpoint = process.env.REACT_APP_BASE_URL + 'v1/projects/list';
  const { data, loading, error } = useFetchData(instance, accounts, inProgress, ProjectListEndpoint);

  const inProgression = (
    <ArgonBadge variant="contained" color="error" size="xs" badgeContent="Project in progress" container />
  );
  const active = <ArgonBadge variant="contained" color="success" size="xs" badgeContent="Active" container />;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorDevelopMessage error={error} />;
  }

  const dataTableData = {
    columns: [
      {
        Header: 'Project name',
        accessor: 'projectName',
        width: '20%',
        Cell: ({
          value: name,
          row: {
            original: { count },
          },
        }) => <ProductCell name={name} count={count} />,
      },
      { Header: 'Region', accessor: 'region' },
      { Header: 'Team Name', accessor: 'teamName' },
      {
        Header: 'status',
        accessor: 'status',
        Cell: ({ value }) => (value === 'active' ? active : inProgression),
      },
      { Header: 'action', accessor: 'action' },
    ],

    rows: data.map((project, index) => ({
      projectName: project.name,
      region: project.region,
      teamName: project.teamName,
      status: project.status === 'active' ? active : inProgression,
      action: <ActionCell /* pass any props needed */ />,
      count: index + 1,
    })),
  };

  return (
    <DataTable
      table={dataTableData}
      entriesPerPage={{
        defaultValue: 5,
        entries: [5, 10, 15, 20, 25],
      }}
      canSearch
    />
  );
};

export default FetchingProjectData;

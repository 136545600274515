// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from '../../dashboard/DashboardLayout';
import DashboardNavbar from '../../dashboard/DashboardNavbar/DashboardNavbar';
import Footer from '../../footer/Footer';

import MyProjectsData from './MyProjectsData';

function MyProjects() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: '100%' }}>
          <Grid item xs={12} lg={11}>
            <Card sx={{ height: '100%' }}>
              <MyProjectsData />
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MyProjects;

import PropTypes from 'prop-types';

const ErrorDevelopMessage = ({ error }) => (
  <div
    style={{
      color: 'red',
      padding: '10px',
      border: '1px solid red',
      borderRadius: '5px',
      marginTop: '10px',
      display: 'inline-block',
      textAlign: 'center',
      width: 'fit-content',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    Error: {error}
  </div>
);
ErrorDevelopMessage.propTypes = {
  error: PropTypes.string.isRequired,
};
// test Pushing
export default ErrorDevelopMessage;

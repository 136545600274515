import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import Home from 'components-mck/home/Home';
import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import AddProject from 'components-mck/management/myProjectData/addProject/AddProject';
import { useArgonController } from 'context';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routesMKC';
import checkGlobalRole from 'utility/checkGlobalRole';
import Logout from './components-mck/dashboard/DashboardNavbar/Logout';
import Login from './components-mck/sign-in/Login';

const AppRoutes = () => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const [state, dispatch] = useArgonController();
  const isAdmin = state.isAdmin;
  checkGlobalRole();
  // console.log('isAdmin from the APPRoutes', isAdmin);
  // console.log('State from the APPRoutes', state);
  console.log('NODE_ENV', process.env.NODE_ENV);
  const getRoutes = (allRoutes) => {
    const filteredRoutes = allRoutes.filter((route) => !(route.adminOnly && !isAdmin));

    return filteredRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL2}v1/api/health`)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log('Response from health', response);
        }
      })
      .catch((error) => {
        console.log('Error: ' + error);
      });
  }, []);

  return (
    <React.Fragment>
      <Routes>
        {isAuthenticated ? (
          <>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/login" element={<Navigate replace to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/add-project" element={<AddProject />} />
            {/* Remove "/*" Route that every time when pae is refreshed to stay on the same page "  */}
            {/* <Route path="*" element={<Navigate to="/home" />} />  */}
            {/* TESTING ROUTES */}
            <Route path="/loadingSpinner" element={<LoadingSpinner />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            {/* <Route path="*" element={<Navigate to="/login" />} /> */}
          </>
        )}
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;

import { useMsal } from '@azure/msal-react';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonInput from 'components/ArgonInput';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTypography from 'components/ArgonTypography';

import DashboardLayout from 'components-mck/dashboard/DashboardLayout';
import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import PropTypes from 'prop-types';

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useShowMessage from 'utility/useShowMessage';

import ErrorDevelopMessage from 'utility/ErrorDevelopMessage';
import usePostData from 'utility/usePostData';
import { capitalizeFirstLetter, formatName, globalRoleOptions } from 'utility/utility';

function AddUser({ onClose }) {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [givenName, setGivenName] = useState('');
  const [globalRole, setGlobalRole] = useState(globalRoleOptions[0].value);
  const { showAlert } = useShowMessage();

  const inviteUserEndPoint = process.env.REACT_APP_BASE_URL + 'v1/users/invite';

  const { postData, loading, error } = usePostData(instance, accounts, 'none', inviteUserEndPoint);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedName = formatName(name);
    const formattedGivenName = formatName(givenName);

    if (!formattedName || !formattedGivenName) {
      showAlert({
        title: 'Validation Error',
        text: 'Names should only contain letters, hyphens, apostrophes, spaces, and be at least 2 characters long.',
        icon: 'error',
      });
      return;
    }

    const userData = {
      name: formattedName,
      email: email,
      globalRole: globalRole,
      givenName: formattedGivenName,
    };

    try {
      const response = await postData(userData); // Use the postData function

      showAlert({
        title: 'Success',
        text: `${capitalizeFirstLetter(givenName)} ${capitalizeFirstLetter(name)}  has been invited successfully`,
        icon: 'success',
        confirmButtonText: 'Continue',
        footer: ' <a href="/support"> If you are having trouble, please contact Support.</a>',
        onConfirm: () => {
          // Clear all inputs and selected options
          setName('');
          setEmail('');
          setGivenName('');
          setGlobalRole(globalRoleOptions[0].value);
          onClose();
          // loading(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        },
      });
    } catch (error) {
      console.error('Error:', error);
      console.error('Error Message:', error.message);
      console.error('Error Code:', error.code);
      console.error('Server Response:', error.response && error.response.data);
      return <ErrorDevelopMessage error={error.message} />;
    }
    console.log('This is an error from AddUser', JSON.stringify(error, null, 2));
  };

  return (
    <DashboardLayout>
      <ArgonBox
        onSubmit={handleSubmit}
        component="form"
        role="form"
        bgColor="secondary"
        variant="gradient"
        borderRadius="lg"
        p={10}
        style={{
          //   animation: 'slide-left 10s cubic-bezier(.215,.61,.355,1.000) 2s both',
          //   animationName: `${slideLeft}`,
          // animation: `10s cubic-bezier(.215,.61,.355,1.000) 12s both ${slideLeft}`,
          height: '50rem',
          boxShadow: '0px 10px 15px rgba(15, 14, 14, 0.1)', // Add this line
        }}
      >
        <ArgonBox mb={2}>
          <ArgonTypography variant="h5" textAlign="center">
            Invite User to your Organization
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Name"
            size="medium"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Given Name"
            size="medium"
            value={givenName}
            onChange={(e) => setGivenName(e.target.value)}
            required
          />
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput
            type="email"
            placeholder="Email@example.de"
            size="medium"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />{' '}
        </ArgonBox>{' '}
        <ArgonBox mb={2}>
          <ArgonSelect
            value={globalRoleOptions.find((option) => option.value === globalRole)}
            onChange={(selectedOption) => setGlobalRole(selectedOption.value)}
            options={globalRoleOptions}
          />
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center">
          <ArgonTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            After you invite a user, their role in the user management settings.
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="center" flexDirection={{ xs: 'column', sm: 'row' }} mt={5}>
          <ArgonButton color="primary" type="submit">
            Invite new User
            {loading ? <LoadingSpinner /> : null}
          </ArgonButton>
          <ArgonBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <ArgonButton color="warning" sx={{ height: '100%' }} onClick={onClose}>
              Cancel
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox mt={3} textAlign="center">
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Do you need &nbsp;
            <ArgonTypography component={Link} to="/support" variant="button" color="info" fontWeight="bold">
              Help?
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}
export default AddUser;

AddUser.propTypes = {
  onClose: PropTypes.func.isRequired,
};

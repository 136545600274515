// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  width: 20rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 234, 234, 0.226);
  z-index: 2000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .blurred {
  filter: blur(1px);
} */
`, "",{"version":3,"sources":["webpack://./src/styles/overlayCards.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;GAEG","sourcesContent":[".overlay {\n  width: 20rem;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(240, 234, 234, 0.226);\n  z-index: 2000;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n/* .blurred {\n  filter: blur(1px);\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import { useEffect, useState } from 'react';
import useShowMessage from './useShowMessage';
import { handleError } from './utility';

const useFetchData = (instance, accounts, inProgress, apiEndpoint) => {
  const { showAlert } = useShowMessage();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const clientID = process.env.REACT_APP_CLIENT_ID;
    const accessTokenRequest = {
      scopes: [`api://${clientID}/API.Access`, 'openid'],
      // scopes: ['openid'],
      account: accounts[0],
    };
    const callApi = async (accessToken) => {
      // console.log('Access Token from use Fetch', accessToken);
      try {
        console.log('Making request to endpoint:', apiEndpoint);
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log('Data from the useFetch', response.data);
        return response.data;
      } catch (error) {
        console.error('Fetch error:', error);
        const errorMessage = handleError(error);
        showAlert({
          title: 'Error Loading Data',
          text: 'An error occurred while loading the data. Please try again later.',
          icon: 'error',
        });
        setError(errorMessage);
        throw error;
      }
    };

    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
        const data = await callApi(accessTokenResponse.accessToken);
        setData(data);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        } else {
          setError(typeof error === 'string' ? error : error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      fetchData();
    }
  }, [instance, accounts, inProgress, apiEndpoint]);

  return { data, loading, error };
};

export default useFetchData;

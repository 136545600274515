import { useMsal } from '@azure/msal-react';
import DataTable from '../../components/DataTable';
/* eslint-disable react/prop-types */
import ArgonBadge from 'components/ArgonBadge';

import axios from 'axios';
import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import ArgonBox from 'components/ArgonBox';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTypography from 'components/ArgonTypography';
import { useState } from 'react';
import ErrorDevelopMessage from 'utility/ErrorDevelopMessage';
import useShowMessage from 'utility/useShowMessage';
import { globalRoleOptions } from 'utility/utility';
import useFetchData from '../../../../utility/useFetchData';
import ActionCell from '../../components/actionCell';
import ProductCell from '../../components/productCell';

const FetchingUsersData = () => {
  const { instance, inProgress, accounts } = useMsal();
  const usersListEndpoint = process.env.REACT_APP_BASE_URL + 'v1/users/list';
  const [delayedData, setDelayedData] = useState([]);

  const { data, loading, error } = useFetchData(instance, accounts, inProgress, usersListEndpoint);
  const notActive = <ArgonBadge variant="contained" color="error" size="xs" badgeContent="User not active" container />;
  const active = <ArgonBadge variant="contained" color="success" size="xs" badgeContent="User active" container />;
  const { showAlert } = useShowMessage();

  const handleUpdateClick = async (user) => {
    console.log('User for update', user);
    const usersUpdateEndpoint = process.env.REACT_APP_BASE_URL + 'v1/users/update';
    const clientID = process.env.REACT_APP_CLIENT_ID;
    const accessTokenRequest = {
      scopes: [`api://${clientID}/API.Access`, 'openid'],
      account: accounts[0],
    };

    const callApi = async (accessToken) => {
      try {
        console.log('Making POST request to:', usersUpdateEndpoint);
        const requestData = {
          email: user.email,
          globalRole: user.roleType,
          // givenName: user.userName,
          // userName: user.givenName,
          name: user.userName,
        };

        console.log('Request data:', requestData);

        const response = await axios.post(
          usersUpdateEndpoint,
          {
            email: user.email,
            globalRole: user.roleType,
            givenName: user.givenName,
            name: user.name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log('Here is response after update', response);
        return response.data;
      } catch (error) {
        console.error('Axios error:', error);
        throw error;
      }
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      await callApi(accessTokenResponse.accessToken);
      // Show success alert
      showAlert({
        title: 'Success',
        text: `User ${user.name} was updated successfully.`,
        icon: 'success',
        confirmButtonText: 'Ok',
        footer: 'Need more Support? Contact us',
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest);
      } else {
        console.error('Error:', error);
        return <ErrorDevelopMessage error={error.message} />;
      }
    }
  };

  const dataTableData = {
    columns: [
      {
        Header: 'User name',
        accessor: 'userName',
        width: '20%',
        Cell: ({
          value: name,
          row: {
            original: { count },
          },
        }) => <ProductCell name={name} count={count} />,
      },
      { Header: 'Email', accessor: 'email' },
      //   { Header: 'Team Name', accessor: 'teamName' },
      {
        Header: 'status',
        accessor: 'status',
        Cell: ({ value }) => (value === 'active' ? active : notActive),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value, row: { original } }) => (
          <ArgonSelect
            value={globalRoleOptions.find((option) => option.value === value)}
            onChange={async (selectedOption) => {
              try {
                original.roleType = selectedOption.value;
                await handleUpdateClick(original);
                console.log('User role updated successfully');
              } catch (error) {
                console.error('An error occurred while updating the user role:', error);
              }
            }}
            options={globalRoleOptions}
          />
        ),
      },
      { Header: 'Action', accessor: 'action' },
    ],

    rows: data.map((user, index) => ({
      userName: user.name + ' ' + user.givenName,
      email: user.email,
      role: user.roleType,
      status: user.status === 'active' ? active : notActive,
      action: <ActionCell user={user} />,
      count: index + 1,
    })),
  };

  return loading ? (
    <LoadingSpinner />
  ) : error ? (
    process.env.NODE_ENV === 'development' ? (
      <ErrorDevelopMessage error={error} />
    ) : (
      showAlert({
        title: 'Error Loading Data',
        text: 'An error occurred while loading the data. You are not eligible for this request. Please contact your Admin.',
        icon: 'error',
      })
    )
  ) : dataTableData.length === 0 ? (
    <ArgonBox
      p={3}
      variant="gradient"
      bgColor="info"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ArgonTypography>No users available. Please add a new user</ArgonTypography>
    </ArgonBox>
  ) : (
    <DataTable
      table={dataTableData}
      entriesPerPage={{
        defaultValue: 10,
        entries: [5, 10, 15, 20, 25],
      }}
      canSearch
    />
  );
};

export default FetchingUsersData;

// @mui material components
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components

// Argon Dashboard 2 PRO MUI example components
import Footer from '../footer/Footer';

// Argon Dashboard 2 PRO MUI base styles
import typography from 'assets/theme/base/typography';
import DashboardLayout from 'components-mck/dashboard/DashboardLayout';
import DashboardNavbar from 'components-mck/dashboard/DashboardNavbar/DashboardNavbar';
import { Fragment } from 'react';
import HomeCards from './HomeCards';
import WelcomeMessage from './WelcomeMessage';

function Home() {
  const { size } = typography;

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid item xs={12} md={6} lg={8}>
          <WelcomeMessage />
        </Grid>
        <HomeCards />
        <Footer />
      </DashboardLayout>
    </Fragment>
  );
}

export default Home;

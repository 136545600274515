import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import DashboardLayout from 'components-mck/dashboard/DashboardLayout';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useState } from 'react';

import Grid from '@mui/material/Grid';
// import './App.css';

const API_KEY = process.env.REACT_APP_CHAT_API_KEY;
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: 'system',
  content: "Explain things like you're talking to a software professional with 2 years of experience.",
};

const Support = () => {
  const [controller] = useArgonController();

  const { darkMode } = controller;

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm MKC Support! How can I help you?",
      sentTime: 'just now',
      sender: 'ChatGPT',
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: 'user',
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = '';
      if (messageObject.sender === 'ChatGPT') {
        role = 'assistant';
      } else {
        role = 'user';
      }
      return { role: role, content: messageObject.message };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: 'gpt-3.5-turbo',
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + API_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: 'ChatGPT',
          },
        ]);
        setIsTyping(false);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3} mb={20} style={{ width: '90%', height: '40rem' }}>
        <Grid
          sx={{
            display: 'grid',
            alignItems: 'center',
            position: 'relative',
            height: '6rem',
            borderRadius: 'lg',
            mb: 3,
          }}
        >
          <ArgonTypography variant="h3" color={darkMode ? 'white' : 'dark'} fontWeight="bold" textAlign="center">
            Welcome to Support
          </ArgonTypography>
        </Grid>

        <MainContainer>
          <ChatContainer>
            <MessageList
              scrollBehavior="smooth"
              typingIndicator={isTyping ? <TypingIndicator content="MKC Support is typing" /> : null}
            >
              {messages.map((message, i) => {
                console.log(message);
                return <Message key={i} model={message} />;
              })}
            </MessageList>
            <MessageInput placeholder="Type message here" onSend={(message) => handleSend(message)} />
          </ChatContainer>
        </MainContainer>
      </ArgonBox>
    </DashboardLayout>
  );
};

export default Support;

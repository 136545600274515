import React from 'react';

// ///////////////////////////////////////////IMPORT///////////////////////////
// const BASE_URL = 'http://localhost:3000';

// //////////////////////////////////////////////////////////////////////
const formatDisplayName = (name) => {
  return name
    .split('/')
    .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
    .join(' ');
};

const formatRoute = (name) => {
  return name
    .split('/')
    .map((segment) => segment.toLowerCase())
    .join('/');
};

export const importDocumentationComponents = () => {
  const context = require.context('../content', true, /\.(jsx|mdx|md)$/);

  const componentsMap = new Map();

  context.keys().forEach((filePath) => {
    const pathWithoutExtension = filePath
      .replace('./', '')
      .replace(/\.(jsx|mdx|md)$/, '')
      .replace('content/', '');

    const pathSegments = pathWithoutExtension.split('/');
    const name = formatDisplayName(pathSegments[pathSegments.length - 1]);
    const key = formatRoute(pathWithoutExtension.replace(/\//g, '-'));
    const route = `/documentation/${pathWithoutExtension}`;
    // Dynamically import the component using React.lazy (commented out for demonstration)
    const component = React.lazy(() => context(filePath));
    // console.log('************************************************');
    // console.log('Path Segment', pathSegments);
    // console.log('name', name);
    // console.log('key', key);
    // console.log('route', route);
    // console.log('component', component);
    // console.log('File-Path for Import Component:::', filePath);

    componentsMap.set(key, {
      filePath,
      name,
      key,
      route,
      component: () => (
        <React.Suspense fallback={<div>Loading...</div>}>
          <div>{component}</div>
        </React.Suspense>
      ),
    });
  });

  const uniqueComponents = Array.from(componentsMap.values());

  return uniqueComponents;
};

export const handleError = (error) => {
  let errorMessage = '';

  if (error.response) {
    console.error('Error Response:', error.response.data);
    errorMessage = `Response Error: ${error.response.status} - ${error.response.data}`;
  } else if (error.request) {
    console.error('Request Error:', error.request);
    errorMessage = 'Request Error: No response received';
  } else {
    console.error('Error Message:', error.message);
    errorMessage = `Error Message: ${error.message}`;
  }

  return errorMessage;
};

//  NAME Format for inputs and display
export const formatName = (name) => {
  const nameRegex = /^[A-Za-z-' ]{2,}$/;

  if (!nameRegex.test(name)) {
    return null;
  }

  name = name.trim();

  const words = name.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1).toLowerCase();
  }

  name = words.join(' ');

  return name;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const globalRoleOptions = [
  { value: 'OWNER', label: 'Owner' },
  { value: 'VIEWER', label: 'Viewer' },
  { value: 'CONTRIBUTOR', label: 'Contributor' },
];

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';
import { useState } from 'react';
// import { Link } from 'react-router-dom'; // Remove if not navigating away
import '../../../styles/overlayCards.css';
import AddProject from './addProject/AddProject'; // Ensure this is the correct path
import FetchingProjectData from './components/FetchingProjectData';

function MyProjectsData() {
  const [showAddProject, setShowAddProject] = useState(false);

  return (
    <div>
      {showAddProject && (
        <div className="overlay">
          <AddProject onClose={() => setShowAddProject(false)} />
        </div>
      )}
      <ArgonBox my={3} className={showAddProject ? 'blurred' : ''}>
        <Card>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <ArgonBox lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium">
                All Projects
              </ArgonTypography>
              <ArgonTypography variant="button" fontWeight="regular" color="text">
                Here we can add some explanation text ?
              </ArgonTypography>
            </ArgonBox>
            <Stack spacing={1} direction="row">
              {/* Removed Link to handle overlay internally */}
              <ArgonButton variant="gradient" color="info" size="small" onClick={() => setShowAddProject(true)}>
                + Add Project
              </ArgonButton>
            </Stack>
          </ArgonBox>
          <FetchingProjectData />
        </Card>
      </ArgonBox>
    </div>
  );
}

export default MyProjectsData;

import LoadingSpinner from 'components-mck/loader/LoadingSpinner';
import ArgonButtonRoot from 'components/ArgonButton/ArgonButtonRoot';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const ArgonButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, loading, disabled, onClick, children, ...rest }, ref) => (
    <ArgonButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === 'gradient' ? 'contained' : variant}
      size={size}
      disabled={disabled || loading} // Disable the button if it's loading
      onClick={onClick}
      ownerState={{ color, variant, size, circular, iconOnly }}
    >
      {loading ? <LoadingSpinner /> : children}
    </ArgonButtonRoot>
  )
);

ArgonButton.defaultProps = {
  size: 'medium',
  variant: 'contained',
  color: 'white',
  circular: false,
  iconOnly: false,
  loading: false, // Add a default value for the loading prop
  disabled: false, // Add a default value for the disabled prop
};

ArgonButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
  color: PropTypes.oneOf(['white', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  loading: PropTypes.bool, // Add typechecking for the loading prop
  disabled: PropTypes.bool, // Add typechecking for the disabled prop
  onClick: PropTypes.func, // Add typechecking for the onClick prop
  children: PropTypes.node.isRequired,
};

export default ArgonButton;

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for ArgonBox
import ArgonBoxRoot from 'components/ArgonBox/ArgonBoxRoot';

const ArgonBox = forwardRef(({ variant, bgColor, color, opacity, borderRadius, shadow, ...rest }, ref) => (
  <ArgonBoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }} />
));

// Setting default values for the props of ArgonBox
ArgonBox.defaultProps = {
  variant: 'contained',
  bgColor: 'transparent',
  color: 'dark',
  opacity: 1,
  borderRadius: 'none',
  shadow: 'none',
};

// Typechecking props for the ArgonBox
ArgonBox.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
};

export default ArgonBox;

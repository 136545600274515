import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Custom styles for the SidenavItem
import { item, itemArrow, itemContent } from './styles/sidenavItem';

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from 'context';

function SidenavItem({ name, active, nested, children, open, ...rest }) {
  const [controller] = useArgonController();
  const { miniSidenav, darkSidenav } = controller;

  return (
    <>
      <ListItem {...rest} component="li" sx={item}>
        <ArgonBox sx={(theme) => itemContent(theme, { active, miniSidenav, darkSidenav, name, nested })}>
          <ListItemText primary={name} />
          {children && (
            <Icon component="i" sx={(theme) => itemArrow(theme, { open, miniSidenav })}>
              expand_less
            </Icon>
          )}
        </ArgonBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default SidenavItem;

import PropTypes from 'prop-types';
import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error('Error:', error, 'Info:', info);
  }

  render() {
    if (this.state.hasError) {
      // You can customize this page or use another fallback UI
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f8d7da',
          }}
        >
          <div
            style={{
              padding: '20px',
              backgroundColor: '#721c24',
              color: '#f8d7da',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            <h1>Warning from ErrorBoundary: Something went wrong.</h1>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
